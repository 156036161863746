import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import SubpageHeader from '../../components/organisms/SubpageHeader';
import styled from 'styled-components';
import Headline from '../../components/atoms/Headline';
import Text from '../../components/atoms/Text';
import { graphql, useStaticQuery } from 'gatsby';

const BeOurPartnerSection = styled.section`
  margin: 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const BigHeadline = styled(Headline)`
  text-transform: uppercase;
  text-align: center;
  font-weight: ${({ theme }) => theme.bold};
  margin-bottom: 50px;
`;

const Content = styled.div`
  margin-bottom: 100px;

  ul {
    padding-left: 30px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ContentText = styled.div`
  @media (min-width: ${({ theme }) => theme.laptop}) {
    display: flex;
    justify-content: space-between;
  }
`;

const ContentTextLeft = styled.div`
  @media (min-width: ${({ theme }) => theme.laptop}) {
    width: 45%;
  }
`;

const ContentTextRight = styled.div`
  @media (min-width: ${({ theme }) => theme.laptop}) {
    width: 45%;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 15px;

  p,
  ul {
    margin-bottom: 15px;
  }
`;

const IframeContainer = styled.div`
  position: relative;
  width: 70%;
  padding-bottom: 39.5%;
  height: 0;
  margin: 0 auto;
`;

const ApartmentsRentPage = () => {
  const imageData = useStaticQuery(query);
  return (
    <MainTemplate
      isMainPage={false}
      title='Mieszkania na wynajem i obsługa'
      metaDescription='Dokonamy oceny i analizy zarówno umowy deweloperskiej, cesji umowy
  deweloperskiej jak i umowy Sprzedaży nieruchomości (gruntowej,
  budynkowej i lokalowej)'
    >
      <SubpageHeader headline='Mieszkania na wynajem i obsługa' />
      <BeOurPartnerSection>
        <BigHeadline data-aos='headline-fadeup'>
          {imageData.datoCmsApartmentsRent.secondAccordionTitle}
        </BigHeadline>
        <Content>
          <ContentText>
            <ContentTextLeft>
              <StyledText
                dangerouslySetInnerHTML={{
                  __html:
                    imageData.datoCmsApartmentsRent.secondAccordionTextLeft,
                }}
              />
            </ContentTextLeft>
            <ContentTextRight>
              <StyledText
                dangerouslySetInnerHTML={{
                  __html:
                    imageData.datoCmsApartmentsRent.secondAccordionTextRight,
                }}
              />
            </ContentTextRight>
          </ContentText>
          <IframeContainer>
            <iframe
              width='560'
              height='315'
              src={imageData.datoCmsApartmentsRent.secondAccordionLink}
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </IframeContainer>
        </Content>
      </BeOurPartnerSection>
    </MainTemplate>
  );
};

const query = graphql`
  query ApartmentsRentPageQuery {
    datoCmsApartmentsRent {
      secondAccordionLink
      secondAccordionTextLeft
      secondAccordionTextRight
      secondAccordionTitle
    }
  }
`;

export default ApartmentsRentPage;
