import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../assets/images/earn-with-me-header.jpg';
import BackgroundImageHouse from '../../assets/images/house-header.jpg';
import BackgroundImageDoradztwo from '../../assets/images/doradztwo-header.jpg';
import Headline from '../atoms/Headline';
import { Link } from 'gatsby';

const HeaderWrapper = styled.header`
  height: 250px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: right 20%;
  position: relative;

  &.isHouse {
    background-image: url(${BackgroundImageHouse});
    background-position: right 70%;
  }

  &.isDoradztwo {
    background-image: url(${BackgroundImageDoradztwo});
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    padding: 0 5%;
    height: 200px;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0 5%;
    height: 300px;
  }
`;

const StyledHeadline = styled(Headline)`
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  max-width: 880px;
  font-weight: ${({ theme }) => theme.semiBold};
  font-size: ${({ theme }) => theme.size20};
`;

const BackToHome = styled(Link)`
  font-weight: ${({ theme }) => theme.regular};
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  position: absolute;
  left: 15px;
  bottom: 15px;

  svg {
    margin-right: 8px;
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    left: 5%;
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    top: 80px;
    bottom: unset;
  }
`;

const SubpageHeader = ({ headline, bgImage }) => {
  return (
    <HeaderWrapper className={bgImage && bgImage}>
      <BackToHome to='/'>
        <svg
          width='38'
          height='12'
          viewBox='0 0 38 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0.845703 5.9668L10.8457 11.7403V0.193294L0.845703 5.9668ZM38 4.9668L9.8457 4.9668V6.9668L38 6.9668V4.9668Z'
            fill='white'
          />
        </svg>
        Powrót
      </BackToHome>
      <StyledHeadline>{headline && headline}</StyledHeadline>
    </HeaderWrapper>
  );
};

export default SubpageHeader;
